document.addEventListener('DOMContentLoaded', () => {
  const bodyElement   = document.querySelector('body'),
    headerElement     = document.querySelector('#header'),
    navigationElement = document.querySelector('#navigation'),
    buttonElement     = document.querySelector('#navigation-button'),
    linksElement      = document.querySelectorAll('.js-navigation-link'),
    windowElement     = window,
    documentElement   = document;

  let was_mobile = (buttonElement.checkVisibility() ? true : false),
    now_mobile = was_mobile,
    scrollTop = 0,
    didResize = true;

  if (!headerElement) return;

  windowElement.addEventListener('resize', () => {
    didResize = true;
  });

  buttonElement.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!now_mobile && !was_mobile) return;

    const navigation_visible = navigationElement.getAttribute('aria-hidden') === 'true' ? false : true;

    buttonElement.setAttribute('aria-expanded', !navigation_visible);
    navigationElement.setAttribute('aria-hidden', navigation_visible);
    headerElement.classList.toggle('l-header--navigation-expanded', !navigation_visible);

    if(navigation_visible) {
      bodyElement.classList.remove('no-scroll');
      bodyElement.style.top = '';
      windowElement.scroll({top: scrollTop});
      navigationElement.setAttribute('tabindex', '-1');
    }
    else {
      scrollTop = windowElement.scrollY;
      bodyElement.classList.add('no-scroll');
      bodyElement.style.top = -scrollTop + 'px';
      navigationElement.removeAttribute('tabindex');
    }
  });

  const closeMenu = (event) => {
    buttonElement.dispatchEvent(new Event('click'));
  }

  const linkClick = (event) => {
    if (event.target.parentElement.classList.contains('l-navigation__item--withsub') || event.target.parentElement.classList.contains('l-navigation__subitem--withside')) {
      return;
    }

    if (now_mobile && navigationElement.getAttribute('aria-hidden') === 'false') {
      closeMenu();
    }
  }
  linksElement.forEach((link) => {
    link.addEventListener('click', linkClick);
  });

  const clickOutside = (event) => {
    if (now_mobile && navigationElement.getAttribute('aria-hidden') === 'false' && !event.target.closest('#header')) {
      closeMenu();
    }
  }
  documentElement.addEventListener('click', clickOutside);
  documentElement.addEventListener('focusin', clickOutside);

  if (now_mobile) {
    navigationElement.setAttribute('aria-hidden', true);
    navigationElement.setAttribute('tabindex', '-1');
  }
  else {
    navigationElement.setAttribute('aria-hidden', false);
  }

  const loop = () => {
    requestAnimationFrame(loop);

    if (didResize) {
      now_mobile = buttonElement.checkVisibility() ? true : false;

      if(now_mobile && !was_mobile) {
        navigationElement.setAttribute('aria-hidden', true);
        navigationElement.setAttribute('tabindex', '-1');
      }

      if(!now_mobile && was_mobile) {
        document.querySelectorAll('.l-navigation__item--subexpanded').removeClass('l-navigation__item--subexpanded');
        document.querySelectorAll('.l-navigation__sub--visible').removeClass('l-navigation__sub--visible');
        document.querySelectorAll('.l-navigation__subitem--sideexpanded').removeClass('l-navigation__subitem--sideexpanded');
        document.querySelectorAll('.l-navigation__side--visible').removeClass('l-navigation__side--visible');

        const navigation_visible = navigationElement.getAttribute('aria-hidden') === 'true' ? false : true;
        if (navigation_visible) {
          closeMenu();
        }

        navigationElement.setAttribute('aria-hidden', false);
        navigationElement.removeAttribute('tabindex');
      }

      was_mobile = now_mobile;
      didResize = false;
    }
  }
  loop();
});
