NodeList.prototype.removeClass = function(...classNames) {
  return this.forEach((node) => {
    node.classList.remove(...classNames);
  });
}

Node.prototype.removeClass = function(...classNames) {
  return this.classList.remove(...classNames);
}

NodeList.prototype.addClass = function(...classNames) {
  return this.forEach((node) => {
    node.classList.add(...classNames);
  });
}

Node.prototype.addClass = function(...classNames) {
  return this.classList.add(...classNames);
}

// https://stackoverflow.com/questions/13351966/create-node-list-from-a-single-node-in-javascript
NodeList.prototype.item = function item(i) {
  return this[+i || 0];
};

Object.defineProperty(
  NodeList.prototype,
  'parentElements', {
    get: function() {
      // https://stackoverflow.com/questions/53350019/how-to-use-map-in-nodelist-in-javascript
      const parentsArray = Array.from(this).map((node) => {
        return node.parentElement;
      });

      // https://stackoverflow.com/questions/13351966/create-node-list-from-a-single-node-in-javascript
      return Reflect.construct(Array, parentsArray, NodeList);
    }
  }
);
