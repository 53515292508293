document.addEventListener('DOMContentLoaded', () => {
  const autosubmit = (event) => {
    const input = event.target;
    input.form.submit();
  };

  document.querySelectorAll('.js-autosubmit').forEach((form) => {
    form.querySelectorAll('input, select').forEach((input) => {
      input.addEventListener('change', autosubmit);
    });
  });
});
