export const htmlToNode = (html, getRoot = true) => {
  const template = document.createElement('template');
  template.innerHTML = html.trim();
  if (getRoot) {
    return template.content;
  }
  return template.content.firstChild;
}

// https://plainjs.com/javascript/styles/get-the-position-of-an-element-relative-to-the-document-24/
export const offset = (element) => {
  const rect = element.getBoundingClientRect(),
    scrollLeft = window.scrollX || document.documentElement.scrollLeft,
    scrollTop = window.scrollY || document.documentElement.scrollTop;

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft
  };
}

// https://stackoverflow.com/questions/54629590/trigger-a-function-when-the-user-scrolls-the-element-into-the-viewport-vanilla
export const isInViewport = (element, position = 'top left') => {
  const coords = getCoordsInViewport(element, position);

  if (
    coords.x <= (window.innerWidth || document.documentElement.clientWidth) &&
    coords.y <= (window.innerHeight || document.documentElement.clientHeight)
  ) {
    return true;
  } else {
    return false;
  }
}

// https://stackoverflow.com/questions/5598743/finding-elements-position-relative-to-the-document
export const getCoordsInViewport = (element, position) => {
  const { top, left, width, height } = element.getBoundingClientRect();
  let point;
  switch (position) {
    case "top left":
      point = {
        x: left,
        y: top
      };
      break;
    case "top center":
      point = {
        x: left + width / 2,
        y: top
      };
      break;
    case "top right":
      point = {
        x: left + width,
        y: top
      };
      break;
    case "center left":
      point = {
        x: left,
        y: top + height / 2
      };
      break;
    case "center":
      point = {
        x: left + width / 2,
        y: top + height / 2
      };
      break;
    case "center right":
      point = {
        x: left + width,
        y: top + height / 2
      };
      break;
    case "bottom left":
      point = {
        x: left,
        y: top + height
      };
      break;
    case "bottom center":
      point = {
        x: left + width / 2,
        y: top + height
      };
      break;
    case "bottom right":
      point = {
        x: left + width,
        y: top + height
      };
      break;
  }
  return point;
}

export const getAbsoluteCoords = (element, position) => {
  const coords = getCoordsInViewport(element, position);
  coords.x += window.scrollX || document.documentElement.scrollLeft;
  coords.y += window.scrollY || document.documentElement.scrollTop;
  return coords;
}
