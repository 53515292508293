document.addEventListener('DOMContentLoaded', function() {
  const headerElement = document.querySelector('#header');

  const linksElements = document.querySelectorAll('.js-navigation-sub .js-navigation-link');
  linksElements.forEach((linkElement) => {
    linkElement.addEventListener('click', function(event) {
      if (!headerElement.classList.contains('l-header--navigation-expanded')) {
        // mobile only
        return;
      }

      event.preventDefault();

      const subElement = linkElement.nextElementSibling;
      if (subElement.classList.contains('l-navigation__sub--visible')) {
        subElement.parentElement.classList.remove('l-navigation__item--subexpanded');
        subElement.classList.remove('l-navigation__sub--visible');
      }
      else {
        const subsElements = document.querySelectorAll('.l-navigation__sub--visible');
        subsElements.forEach((subElement) => {
          subElement.classList.remove('l-navigation__sub--visible');
          subElement.parentElement.classList.remove('l-navigation__item--subexpanded');
        });

        linkElement.parentElement.classList.add('l-navigation__item--subexpanded');
        subElement.classList.add('l-navigation__sub--visible');
      }
    });
  });

  // const sublinksElements = document.querySelectorAll('.js-navigation-side .js-navigation-sublink');
  // sublinksElements.forEach((sublinkElement) => {
  //   sublinkElement.addEventListener('click', function(event) {
  //     if (!headerElement.classList.contains('l-header--navigation-expanded')) {
  //       // mobile only
  //       return;
  //     }

  //     event.preventDefault();

  //     const sideElement = sublinkElement.nextElementSibling;
  //     if (sideElement.classList.contains('l-navigation__side--visible')) {
  //       sublinkElement.parentElement.classList.remove('l-navigation__subitem--sideexpanded');
  //       sideElement.classList.remove('l-navigation__side--visible');
  //     }
  //     else {
  //       const sidesElements = document.querySelectorAll('.l-navigation__side--visible');
  //       sidesElements.forEach((sideElement) => {
  //         sideElement.classList.remove('l-navigation__side--visible');
  //         sideElement.parentElement.classList.remove('l-navigation__subitem--sideexpanded');
  //       });

  //       sublinkElement.parentElement.classList.add('l-navigation__subitem--sideexpanded');
  //       sideElement.classList.add('l-navigation__side--visible');
  //     }
  //   });
  // });
});
