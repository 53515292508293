document.addEventListener('DOMContentLoaded', () => {
  const goNext = (loop) => {
    const visibleClass = loop.dataset.visibleClass;
    const active = loop.querySelector('.' + visibleClass);
    const next = active.nextElementSibling || active.parentElement.firstElementChild;
    active.classList.remove(visibleClass);
    next.classList.add(visibleClass);

    setTimeout(() => {
      goNext(loop);
    }, 1200 + 800);
  };

  document.querySelectorAll('.js-loop-fade').forEach((loop) => {
    setTimeout(() => {
      goNext(loop);
    }, 1200);
  });
});
